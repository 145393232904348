import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import NavBar from '../components/Navbar/Navbar'
import './Surgeries.css'
import PatientTable from '../components/PatientTable/PatientTable';
import db from '../data/db.json';


const Surgeries = () => {
    const params = useParams();
    var place;
    let navigate = useNavigate();

    const location = useLocation();

    if(location.state === null) {
        navigate("/");
    }

    if(!location.state.name) {
        place = this.state.name;
    }
    else {
        place = location.state.name;
    }

    //const 
    const API_URL= "https://kelaserver.herokuapp.com/" + place;
    //const API_URL = 'http://localhost:8000/' + place;
    // npx json-server --watch data\patientdash.json --[port 8000]
    const [patients, setPatients] = useState([]);
    
    
    const useType = location.state.name;


    useEffect(() => {

        const fetchItems = async () => {
            try{
                // const response = await fetch(API_URL);
                // const listPatients = await response.json();
                const department = window.location.pathname.replace('/','');
                setPatients(db[department]);
                
                
            } catch (err) {
                console.log("Error loading patients.");

            }
        }

        fetchItems();
        
    },[]);

    const column = [
        { heading: "OR", value: "or" },
        { heading: "Time", value: "time"},
        { heading: "Patient Name", value: "patient name"},
        { heading: "MRN", value: "mrn" },
        { heading: "Age", value: "age" },
        { heading: "Procedure", value: "primary procedure" },
        { heading: "Anesthesia", value: "anesthesia" },
        { heading: "Bleed Risk", value: "bleed risk level" },
        { heading: "Infection Risk", value: "infection risk" },
        { heading: "Recommendations", value: "recommendations" }  

    ]
  
  
    return (
        
        <div>
            <div className='body'>
                <NavBar useType={useType}/>
                <PatientTable className='table' patients ={patients} column={column} keyName={location.state.name} useType={location.state.name}/>
            </div>
            
            <footer className='footer'></footer>
        </div>
    
  )
}

export default Surgeries