import React, { createContext, useContext, useState } from "react";
import {useNavigate } from "react-router-dom";

const authContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [drName, setDrName] = useState('');
  const navigate = useNavigate();

  const login = (userDetails) => {
    if(userDetails.email === "demo@bd.com" && userDetails.pwd === "Demo") {
        setUser(userDetails);
        setDrName("BD");
        navigate("/Usage");
    }
    else if(userDetails.email === 'jane.lee@hospital.org' && userDetails.pwd === "Demo") {
      setUser(userDetails);
      setDrName("DR. LEE");
      navigate("/Usage");
    }
    else if(userDetails.email === 'henry.khan@hospital.org' && userDetails.pwd === "Demo") {
      setUser(userDetails);
      setDrName("DR. KHAN");
      navigate("/Usage");
    }
    else if(userDetails.email === 'liz.hernandez@hospital.org' && userDetails.pwd === "Demo") {
      setUser(userDetails);
      setDrName("DR. HERNANDEZ");
      navigate("/Usage");
    }
  };

  const logout = () => {
    setUser({});
    navigate("/");
  };

  return (
    <authContext.Provider value={{ user, drName, login, logout }}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};


