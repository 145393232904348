import React from 'react';
import './App.css';
import Usage from './pages/Usage';
import Surgeries from './pages/Surgeries';
import RiskPredictions from './pages/RiskPredictions';
import Login from './pages/Login';
import RiskFactors from './pages/RiskFactors';
import {BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { RequireAuth } from './RequireAuth';
import { AuthProvider } from './useAuth';

//npx json-server --watch data/patient_factors.json --port 8001  
//npx json-server --watch data/patientdash.json --port 8000
//npx json-server --watch data/complete_patient_factors.json --port 8000

function App() {


  return (
    
    <Router>
      <AuthProvider>
        <Routes>
              {/*public*/ }
              <Route path="/" element={<Login />}/>
              
              {/*private*/}
              <Route path="/Usage" element={<RequireAuth><Usage /></RequireAuth>}/>
              <Route path="/General_Surgery" element={<Surgeries />}/>
              <Route path="/Gynecology" element={<Surgeries />}/>
              <Route path="/Orthopedics" element={<Surgeries />}/>
              <Route path="/RiskPredictions/General_Surgery/:patientnamemrn" element={<RiskPredictions useType="General_Surgery"/>}/>
              <Route path="/RiskPredictions/Gynecology/:patientnamemrn" element={<RiskPredictions useType="Gynecology" />}/>
              <Route path="/RiskPredictions/Orthopedics/:patientnamemrn" element={<RiskPredictions useType="Orthopedics"/>}/>
              <Route path="/RiskFactors/General_Surgery/:patientnamemrn" element={<RiskFactors useType="General_Surgery"/>}/>
              <Route path="/RiskFactors/Gynecology/:patientnamemrn" element={<RiskFactors useType="Gynecology" />}/>
              <Route path="/RiskFactors/Orthopedics/:patientnamemrn" element={<RiskFactors useType="Orthopedics"/>}/>


        </Routes>
      </AuthProvider>
    </Router>
    
)};


export default App;
