import React  from 'react'
import './Kelacard.css'

const Kelacard = (props) => {
  
    return (
    <div className='card-container'>
        <div className='card-title'>
            {props.title}
        </div>
        <div className='kelacard' style={{ backgroundColor: props.level === "High" ? '#F2C76D' : '#C2DDF2'}}>
            <div className='level'>
                {`${props.level}`.toUpperCase()}
            </div>
            <div className='risks'>
                <div style={{order:1}}>Actual Risk: <emph>{Math.round(props.risk * 100)}%</emph> </div>  
                <div style={{order:2}}>Relative Risk: <emph>{props.relative}</emph></div>  
            </div>
        </div>
    </div>
    
  )
}

export default Kelacard