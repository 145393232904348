import React from 'react'
import './PatientTable.css'
import { Link } from 'react-router-dom'
import A from '../../images/A.png'
import IP from '../../images/IP.png'
import S from '../../images/S.png'
import H from '../../images/H.png'

const PatientTable = ({patients, column, keyName, useType}) => {
    if (!patients) return;
    console.log(patients)

    const current = new Date();
    const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;
    
    var usageType = "";
    if(keyName === "General_Surgery") {
        usageType = "General Surgery";
    }
    else {
        usageType = keyName;
    }

    var bg;
    var legend = new Set();
    var order;
    function getRecommendations(recommendation, i) {
            legend.add(recommendation);
            if(recommendation === "NB" || recommendation === "B") {
                order = 1;
            }
            else if(recommendation === "A" || recommendation === "H") {
                order = 2;
            }
            else if(recommendation === "S" || recommendation === "IP") {
                order = 3;
            }
            

            return(
                
                <img src={require('../../images/' + recommendation + ".png")} style={{order: order}}></img>
            )

        };

    function getTheTable(patients) {
        return(
            <table>
                <thead>
                    <tr>
                        {column.map((item, index) => <th key={column.id}>{item.heading}</th>)}
                    </tr>
                </thead>
            { patients.map((patientmap) => {
                return (
                    <tbody>
                        { Object.values(patientmap).map((patient) => {
                            console.log(patient);
                            return (
                                <tr key={patient.id}>
                                    <td>{patient["OR"]}</td>
                                    <td>{patient["Procedure Time"]}</td>
                                    <td><a href={`/RiskPredictions/${useType}/${patient["Patient Name"]}_${ patient["MRN"]}`}>{patient["Patient Name"]}</a></td>
                                    <td>{ patient["MRN"]}</td>
                                    <td>{ patient["Age"]}</td>
                                    <td>{ patient["Primary Procedure"]}</td>
                                    <td>{ patient["Anesthesia"]}</td>
                                    
                                    <td style={{ backgroundColor: patient["Bleed Risk Level"] === "High" ? '#F2C76D' : '#C2DDF2'}}>{ patient["Bleed Risk Level"]}</td>
                                    <td style={{ backgroundColor: patient["Infection Risk Level"] === "High" ? '#F2C76D' : '#C2DDF2'}}>{patient["Infection Risk Level"]}</td>
                                    <td className='circle-container'>
                                        {patient["Recommendation"].map((recommendation, i) => 
                                            getRecommendations(recommendation, i))}
                                        {patient["Blood Preparation Recommendation"] === "Prepare Blood Type and Crossmatch" ? <img src={require('../../images/B.png')}></img> : <img src={require('../../images/NB.png')}></img> }
                                        
                                    </td>
                                </tr>
                            );}
                        )}
                    </tbody>
                );})
            }   
            </table>
        )
    };



    var legendbg;
    var legendAr = [];
    var text;
    function getTheLegend(legend) {
        legendAr = [...legend];
        return(legendAr.map((legendItem) => {
            if(legendItem=== "H") {
                text = "Any Hemostat";
            }
            else if(legendItem=== "IP") {
                text = "Infection Prevention Bundle";
            }
            else if(legendItem === "S") {
                text = "Standard Protocol";
            }
            else if(legendItem === "A") {
                text = "Arista";
            }
            
            return(
                <div className='legend-row'>
                    <div className='circle' style={{backgroundColor:legendbg}}>
                        
                        <img src={require('../../images/' + legendItem + ".png")}></img>
                        
                    </div>
                    <div>
                        {text}
                    </div>
                </div>
                
            )
        }))
    }

        



  return (
    <div className='overall-container'>
        <div className='table-container'>
            <div>
                <div className='caption'>{usageType} - Your Surgeries Today {date}</div>
                {getTheTable(patients)}
            </div>
        </div>

        <div className='legend-container'>
            <div className='legend-column'>
                <div className='legend-row'>
                    <div>
                        <img src={require('../../images/B.png')}></img>
                    </div>
                    <div className='text'>
                        Prepare Blood Type and Crossmatch
                    </div>
                </div>
                <div className='legend-row'>
                    <div>
                        <img src={require('../../images/A.png')}></img>
                    </div>
                    <div className='text'>
                        Arista
                    </div>
                </div>
                <div className='legend-row'>
                    <div>
                        <img src={require('../../images/IP.png')}></img>
                    </div>
                    <div className='text'>
                        Infection Prevention Bundle
                    </div>
                </div>
            </div>
            <div className='legend-column'>
                <div className='legend-row'>
                    <div>
                        <img src={require('../../images/NB.png')}></img>
                    </div>
                    <div className='text'>
                        No Preparation of Blood Needed
                    </div>
                </div>
                <div className='legend-row'>
                    <div>
                        <img src={require('../../images/H.png')}></img>
                    </div>
                    <div className='text'>
                        Any Hemostat
                    </div>
                </div>
                <div className='legend-row'>
                    <div>
                        <img src={require('../../images/S.png')}></img>
                    </div>
                    <div className='text'>
                        Standard Protocol
                    </div>
                </div>
            </div>
        </div>
    

    </div>
  )
   
}

export default PatientTable

