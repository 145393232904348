import React, {useState} from 'react'
import './Recommendation.css'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'



const Recommendation = ({recs, bloodprep, rect1, rect2, rect3, rect4, bleedval, infectval}) => {
    var type; //1 == high high, 2 == high low, 3 == low high, 4 == low low
    const [bloodPrep, setBloodPrep] = useState()
    const [bleed, setBleed] = useState();
    const [infection, setInfection] = useState();
    const [ isAlertVisible, setIsAlertVisible ] = useState(false);
    

    function handleBloodPrepToggle(event) {
        const {target: {value: _value}} = event;
        setIsAlertVisible(true);
        setBloodPrep(_value);

    }

    function handleBleedToggle(event) {
        const {target: {value: _value}} = event;
        setIsAlertVisible(true);
        setBleed(_value);
      }

      function handleInfectToggle(event) {
        const {target: {value: _value}} = event;
        setIsAlertVisible(true);
        setInfection(_value);
      }

    setTimeout(() => {
        setIsAlertVisible(false);
    }, 3200);

  return (
    <div className='outer-rec-container'>
        <div className='recommendations'>
            <div className='bloodprep-rec'>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value= {bloodPrep}
                    onChange={handleBloodPrepToggle}
                >
                    <div className='rect-group'>
                        <div className='rectangle' style={{backgroundColor: (bloodprep === 'Prepare Blood Type and Crossmatch') ?  "#FFC413" : '#EDEDED' }}></div>
                        <div className='formlabel'><FormControlLabel value="prepblood" control={<Radio />} label="Prepare Blood Type and Crossmatch" /></div>
                    </div>
                    <div className='rect-group'>
                        <div className='rectangle' style={{backgroundColor:(bloodprep === 'No Preparation of Blood Needed') ?  "#2198F3" : '#EDEDED' }}></div>
                        <div className='formlabel'><FormControlLabel value="noprep" control={<Radio />} label="No Preparation of Blood Needed" /></div>
                    </div>
                </RadioGroup>
            </div>
            <div className='bleed-rec'>
                
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={bleed} 
                    onChange={handleBleedToggle}
                >
                    <div className='rect-group'>
                        <div className='rec'></div>
                        <div className='rectangle' style={{backgroundColor: rect1}}></div>
                        <div className='formlabel'><FormControlLabel value="arista" control={<Radio />} label="Use BD Arista" /></div>
                    </div>
                    <div className='rect-group'>
                        <div className='rec'></div>
                        <div className='rectangle' style={{backgroundColor: rect2}}></div>
                        <div className='formlabel'><FormControlLabel value="hemostat" control={<Radio />} label="Any Hemostat" /></div>
                    </div>
                </RadioGroup>
            </div>
            <div className='infection-rec'>

                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={infection}
                    onChange={handleInfectToggle}

                >
                    <div className='rect-group'>
                        <div className='rectangle' style={{backgroundColor: rect3}}></div>
                        <div className='formlabel'><FormControlLabel value="infectionbundle" control={<Radio />} label="Infection Prevention Bundle" /></div>
                    </div>
                    <div className='rect-group'>
                        <div className='rectangle' style={{backgroundColor: rect4}}></div>
                        <div className='formlabel'><FormControlLabel value="standard" control={<Radio />} label="Standard Protocol" /></div>
                    </div>
                </RadioGroup>
            </div>
        

        
        </div>
        {isAlertVisible && <div className='alert-container'>
               <div className='alert-inner'>Thank you. Your choice has been recorded.</div>
           </div>}
    </div>
   
  )
}

export default Recommendation