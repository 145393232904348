import React, { useEffect, useState } from 'react'
import './Login.css'
import ehr from '../images/EHR-1_ai.ai.png'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import { useAuth } from '../useAuth'

const Login = () => {
    const auth = useAuth();

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');

    const [errMsg, setErrMsg] = useState('');

    
    const navigate = useNavigate();

    window.onpopstate = () => {
        navigate("/");
      }

    
    const [success, setSuccess] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        auth.login({ email: email, pwd: pwd });
        if(!auth.user.email)
        { 
            setSuccess(false);
        }
       
    }

  return (
    <div className='background'>
        <form onSubmit={handleSubmit}>
            <div className='top-margin'>
                <div className='logo-container'>
                    <img src={ehr} style={{background: '#0367B4'}}></img>  
                </div>
            </div>
            <div className='input-area'>
                Sign in with your email and password
                <div className='form'>
                    <label htmlFor="email">Email</label>
                    <input 
                        type="text" 
                        placeholder="name@host.com" 
                        name="email" 
                        id="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    >    
                    </input>
                </div>
                <div className='form'>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password" 
                        placeholder="Password" 
                        name="password" 
                        id="password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                        required
                    >
                    </input>
                </div>
                <div className='help-link'>
                    <a href="#">Forgot your password?</a>
                </div>
                
                <div className='submit' >
                    <button onClick={handleSubmit}>Sign In</button>
                </div>
            </div>
            {!success && <div className='login-alert'>
               <div className='alert-inner'>Incorrect username or password.</div>
           </div>}

        </form>

        
    </div>
  )
}

export default Login