import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import Foldernav from '../components/Foldernav/Foldernav'
import Navbar from '../components/Navbar/Navbar'
import './RiskFactors.css'
import FactorChart from '../components/FactorChart'
import db from '../data/db.json';

const RiskFactors = (props) => { /*When in real practice, will look up the corresponding JSON based on name/mrn*/
  //const API_URL= "http://localhost:8001/patient_factors/";
  const ROOT = "https://kelaserver.herokuapp.com/"
  //const ROOT = 'http://localhost:8000/'

  // npx json-server --watch data\singlepatient.json --[port 8001]
  const [chartData, setChartData] = useState({});


  const { patientnamemrn } = useParams();
  const [name, mrn] = patientnamemrn.split("_");

  const [bleedinf, setBleedInf] = useState("Bleed Risk Factors");
  const [risk, setRisk] = useState("All");

  const handleBleedInfChange = (e) => {
    setBleedInf(e.target.value);
  };

  const handleRiskChange = (e) => {
    setRisk(e.target.value);
  };
  
  

  useEffect(() => {

    const fetchItems = async () => {
        try{
            // const response = await fetch(ROOT + props.useType);
            // const data = await response.json();
            setChartData(db[props.useType]);
        } catch (err) {
            console.log("Error loading single patient info.");

        }
    }

    fetchItems();
    
  },[]);

  var patientData = [];
    for(var i = 0; i < chartData.length; i++) {
      if(typeof chartData[i][mrn] !== 'undefined') {
        patientData = chartData[i][mrn];
      }
    }


  

  return (
    
    <div>
      
      <div className='body'>
        <Navbar useType={props.useType}/>
        <Foldernav name={name} mrn={mrn} useType={props.useType}/>

        <div className='dropdown-container'>
          <div className='bleedorinfect-dropdown'>
            <select onChange={handleBleedInfChange}>
              <option value="Bleed Risk Factors">Bleeding Risk</option>
              <option value="Infection Risk Factors">Infection Risk</option>
            </select>
          </div>
          <div className='incordec-dropdown'>
            <select onChange={handleRiskChange}>
            <option value="All">All Factors</option>
              <option value="Increasing Risk">Increasing Risk</option>
              <option value="Decreasing Risk">Decreasing Risk</option>
            </select>
          </div>
        </div>
        <div className='chart'><FactorChart chartData={patientData} mrn={mrn} useType={props.useType} bleedorinf={bleedinf} risks={risk}/></div>    
        <div className='padding'></div>
      </div>
      <footer className='footer'></footer>
    </div>
  )
}

export default RiskFactors